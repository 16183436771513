<template>
  <q-form
    ref="forgotForm"
    @submit="onSubmit"
  >
    <q-card-section>
      <div class="q-pa-sm">
        <h5 class="q-mb-none q-mt-xs text-weight-bold">
          Reset Password
        </h5>
      </div>
    </q-card-section>

    <q-card-section
      v-if="!!serverErrorMessage"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm text-red"
        inset
      >
        {{ serverErrorMessage }}
      </div>
    </q-card-section>

    <q-card-section
      v-if="!!serverMessage"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm doc-note doc-note-success"
        inset
        v-html="serverMessage"
      />
    </q-card-section>

    <q-card-section>
      <div class="q-pa-sm q-gutter-y-sm column">
        <q-input
          ref="email"
          v-model="email"
          label="Email"
          :rules="[val => !!val || 'Field is required', val => getServerError('email')]"
          @blur="clearServerError('email')"
        />

        <q-btn
          type="submit"
          color="primary"
          no-caps
          :loading="loading"
        >
          Reset Password
        </q-btn>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions align="between">
      <q-btn
        to="/auth/login"
        color="secondary"
        flat
        no-caps
      >
        Log In
      </q-btn>
      <q-btn
        to="/auth/register"
        color="secondary"
        flat
        no-caps
      >
        Register
      </q-btn>
    </q-card-actions>
  </q-form>
</template>

<script>
// import { AuthActionTypes } from 'src/modules/auth/store/action-types';
import { serverErrorMixin } from 'src/mixins/server_error.mixin';
import { ApiService } from 'src/services/api.service';

export default {
  mixins: [serverErrorMixin],
  data() {
    return {
      email: '',
      isPwd: true,
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      try {
        const apiData = await ApiService.post('/jwt/auth/forgot', { email: this.email });

        console.log('in forgot save', apiData);
        this.serverMessage = apiData.data.message;

        return true;
      } catch (err) {
        if (err.response) {
          this.serverErrorMessage = err.response.data.message;

          if (err.response.data.errors) {
            this.serverErrors = err.response.data.errors;
          } else {
            console.log('no serverErrors');
          }
          console.log('doing validate');
          this.$refs.forgotForm.validate();
        } else {
          console.log('Error: ', err.message);
          throw err;
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
